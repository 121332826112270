<template>
  <div class="element-property element-property--map">
    <CyInfraViewProperty
      v-for="[key, value] in _.entries(mapProperty)"
      :key="key"
      :property="{ key, value }"
      class="map-item"/>
  </div>
</template>

<script>
export default {
  name: 'CyInfraViewMapProperty',
  components: {
    CyInfraViewProperty: () => import('@/components/infra-view/property'),
  },
  props: {
    mapProperty: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
  .element-property {
    &--map {
      padding-left: 0;
    }

    &.map-item {
      margin-bottom: 0;

      &:first-child {
        margin-left: -0.5em;
      }

      ::v-deep .copy-value-btn {
        bottom: 0;
      }
    }
  }
</style>
