<template>
  <div class="right-panel">
    <div class="right-panel-header">
      <div class="right-panel-header__element-info element-info">
        <img
          :src="element._image"
          alt="Logo"
          class="element-info__icon">
        <div class="element-info__canonical-info canonical-info">
          <CyTooltip left>
            <template #activator="{ on: nameTooltip }">
              <h3
                class="canonical-info__name"
                v-on="nameTooltip">
                {{ nodeName || element._name }}
              </h3>
            </template>
            <span>{{ nodeName || element._name }}</span>
          </CyTooltip>
          <CyInfraViewResourceType :resource="element"/>
        </div>
      </div>
      <div class="right-panel-header__actions actions">
        <CyButton
          icon-only
          theme="primary"
          variant="tertiary"
          class="actions__btn actions__docs"
          icon="help_outline"
          sm
          @click="openDocPage"/>

        <CyButton
          icon-only
          theme="primary"
          variant="tertiary"
          icon="my_location"
          class="actions__btn actions__center"
          sm
          @click="$emit('center-element')"/>

        <v-icon
          class="actions__btn actions__close"
          small
          @click="$emit('close-right-panel')">
          close
        </v-icon>
      </div>
    </div>
    <div
      :class="['right-panel-description', {
        'right-panel-description--expanded': isDescriptionExpanded,
      }]">
      <template v-if="shortDescriptionLength >= 85">
        <span v-if="isDescriptionExpanded">{{ element._shortDescription }}</span>
        <span v-else>{{ _.truncate(element._shortDescription, { length: 85 }) }}</span>
        <button
          v-if="!isDescriptionExpanded"
          class="description__reveal"
          @click="isDescriptionExpanded = true">
          {{ $t('seeMore') }}
        </button>
        <button
          v-else
          class="description__collapse"
          @click="isDescriptionExpanded = false">
          {{ $t('collapse') }}
        </button>
      </template>
      <template v-else>
        {{ element._shortDescription }}
      </template>
    </div>
    <div class="right-panel-content">
      <div
        v-if="element._canAddTags"
        class="right-panel-content__tags">
        <CyInfraViewRightPanelTags
          :entity="element"
          readonly/>
      </div>
      <div class="right-panel-content__search">
        <v-text-field
          v-model="searchQuery"
          append-icon="search"
          placeholder="Search"
          clearable/>
      </div>
      <div class="right-panel-content__properties">
        <CyInfraViewProperty
          v-for="[key, value] in _.entries(elementProperties)"
          :key="key"
          :property="{ key, value }"
          class="element-property"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CyInfraViewRightPanelTags from '@/components/infra-view/right-panel-tags'
import CyInfraViewProperty from '@/components/infra-view/property'
import CyInfraViewResourceType from '@/components/infra-view/resource-type'

export default {
  name: 'CyInfraViewRightPanel',
  components: {
    CyInfraViewRightPanelTags,
    CyInfraViewProperty,
    CyInfraViewResourceType,
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isDescriptionExpanded: false,
    searchQuery: '',
  }),
  computed: {
    ...mapState('organization/project/infraview', {
      diagram: (state) => state.diagram,
    }),
    shortDescriptionLength () {
      return _.get(this.element, '_shortDescription', '').length
    },
    elementProperties () {
      const { tags, _private, ...elementProperties } = this.element
      return _.isEmpty(this.searchQuery)
        ? elementProperties
        : _.fromPairs(_.entries(elementProperties).filter(([key]) => _.toLower(key).includes(_.toLower(this.searchQuery))))
    },
    nodeName () {
      const cell = _.find(this.diagram?.cells, ['attrs.canonical', this.element._canonical])
      return _.get(cell, 'attrs.nodeName')
    },
  },
  methods: {
    openDocPage () {
      const provider = this.element._provider
      const type = this.element._type.substring(provider.length + 1)
      const typeChar = this.element._entityType[0]
      window.open(`${$docLinks.terraform.providers}/${provider}/${typeChar}/${type}`, '_blank')
    },
  },
  i18n: {
    messages: {
      en: {
        hideTags: 'Hide tags',
        optionalFields: 'Optional fields',
        showMoreTags: 'Show {count} tags',
      },
      es: {
        hideTags: 'Ocultar tags',
        optionalFields: 'Campos opcionales',
        showMoreTags: 'Mostrar {count} tags',
      },
      fr: {
        hideTags: 'Cacher tags',
        optionalFields: 'Champs optionnels',
        showMoreTags: 'Afficher {count} tags',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  $grey-border: 1px solid get-color("grey");

  .right-panel {
    display: flex;
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    width: 480px;
    background: white;
    box-shadow: map.get($box-shadow, normal);

    &-header {
      display: grid;
      grid-template-areas: ". element_info actions";
      grid-template-columns: 0.75em 1fr 7em;
      flex: 0 0 5em;
      width: 100%;
      border-bottom: 1px solid get-color("grey", "light-3");

      &__actions,
      &__element-info {
        align-items: center;
      }

      &__element-info {
        display: grid;
        grid-area: element_info;
        grid-template-areas: "icon text";
        grid-template-columns: 3em auto;

        .element-info__icon {
          grid-area: icon;
          width: 2.5em;
        }

        .canonical-info {
          max-width: 250px;

          &__name {
            margin-bottom: -4px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      &__actions {
        display: grid;
        grid-area: actions;
        grid-template-areas: "docs center close";

        &__btn {
          margin: 0;
        }

        .actions {
          &__docs,
          &__center {
            margin: 0;
          }

          &__docs {
            right: -0.25em;
            grid-area: docs;
            opacity: 0.5;

            &:hover {
              opacity: 1;
            }
          }

          &__center {
            left: -0.25em;
            grid-area: center;
          }

          &__close {
            grid-area: close;
            align-self: flex-start;
            margin-top: 0.5em;
            margin-right: 0.5em;
            color: get-color("grey", "dark-1");
          }
        }
      }
    }

    &-description {
      margin: 0 1em;
      padding: 0.75em 0.25em;
      border-top: $grey-border;
      border-bottom: $grey-border;
      color: get-color("primary");
      font-size: 12px;
      font-style: italic;
      font-weight: 500;
      text-overflow: ellipsis;

      &--expanded {
        max-height: 100%;
        overflow: initial;
      }

      .description__reveal,
      .description__collapse {
        color: get-color("secondary");
        font-style: normal;
        font-weight: 600;

        @extend %clickable;

        &:hover {
          color: get-color("accent");
        }
      }
    }

    &-content {
      flex: 1 1 100%;
      width: 100%;
      padding: 0.5em;
      overflow-y: auto;

      &__tags ::v-deep {
        border-bottom: $grey-border;

        .right-panel-content-tags {
          padding: 0 0 0.5em;
        }

        .v-chip__content {
          padding: 0 0.5em;
        }
      }

      @extend %cy-scrollbars-always-show;
      @extend %cy-scrollbars-only-y;
    }
  }
</style>
