<template>
  <div
    class="d-flex"
    @mouseleave="hoverDetails = false"
    @mouseenter="hoverDetails = true">
    <CyTooltip left>
      <template #activator="{ on }">
        <div
          :class="`state d-flex align-self-stretch ${stateColor}`"
          v-on="on"/>
      </template>
      <span class="uppercase">
        <span>{{ $t('infra.State') }}</span>:
        <b>{{ value.State.Name }}</b>
      </span>
    </CyTooltip>
    <div class="d-flex flex-column width-100">
      <v-row
        class="ma-0 pa-2 line header line__header">
        <v-col
          cols="9"
          class="pa-0">
          <div class="prop-title grey--text text--darken-1">
            {{ $t('infra.instanceName') }}
            <CyCopyBtn
              v-if="instanceName"
              :copy-hint="instanceName"
              :copy-value="instanceName"
              small/>
          </div>
          <div class="prop-value">
            {{ instanceName }}
          </div>
        </v-col>
        <v-col
          cols="3"
          class="pa-0 region">
          <div
            class="prop-title grey--text text--darken-1">
            {{ $t('infra.AvailabilityZone') }}
          </div>
          <div class="prop-value">
            <v-icon color="secondary">
              location_on
            </v-icon> {{ value.Placement.AvailabilityZone }}
          </div>
        </v-col>
      </v-row>
      <div
        v-if="fields.length > 0"
        ref="line-subheader"
        :class="['d-flex flex-wrap ma-0 pa-2 pb-0 grey lighten-4 line details line__subheader', { 'line__subheader--sticky': $refs['line-subheader'] && $refs['line-subheader'].clientHeight < 60 }]">
        <div
          v-for="field of fields"
          :key="field"
          class="d-flex flex-column pa-0 mr-8 mb-2">
          <div
            class="prop-title">
            {{ $t(`infra.${field}`) }}
            <CyCopyBtn
              v-if="String(getPropertyValue(field))"
              :copy-hint="String(getPropertyValue(field))"
              :copy-value="String(getPropertyValue(field))"
              small/>
          </div>
          <div
            class="prop-value"
            v-html="getPropertyValue(field)"/>
        </div>
        <v-icon
          :color="hoverDetails ? 'teal': 'teal lighten-3'"
          class="details-icon"
          @click="$toggle.details">
          {{ details ? 'keyboard_arrow_up': 'keyboard_arrow_down' }}
        </v-icon>
      </div>
      <transition name="expand">
        <div
          v-if="detailsFields.length > 0 && details"
          :class="['d-flex flex-column px-2 pt-2 grey lighten-4 line details details__content', { 'details__content--visible': details }]">
          <div
            v-for="field of detailsFields"
            :key="field"
            class="mb-4">
            <div
              class="prop-title">
              {{ $t(`infra.${field}`) }}
              <CyCopyBtn
                v-if="String(getPropertyValue(field))"
                :copy-hint="_.isObjectLike(getPropertyValue(field)) ? $t('theValueBelow') : String(getPropertyValue(field))"
                :copy-value="String(getPropertyValue(field))"
                small/>
            </div>
            <div
              class="prop-value"
              v-html="getPropertyValue(field)"/>
          </div>
        </div>
      </transition>

      <CyTagList
        :tags="tags"
        contained
        variant="primary"
        class="ma-1"
        small>
        <template #tag="{ tag }">
          <CyTag
            variant="primary"
            class="tag-filter"
            :label="tag.Key"
            small>
            {{ tag.Value }}
          </CyTag>
        </template>
      </CyTagList>
    </div>
  </div>
</template>

<script>
import CyCopyBtn from '@/components/copy-btn'
import CyTagList from '@/components/tag-list'

const IAM_INSTANCE_PROFILE_REGEX = /\/(.*)/
const EXCLUDED_FIELDS = ['State', 'Placement', 'Tags']

export default {
  name: 'CyInfraViewOldInstance',
  components: {
    CyCopyBtn,
    CyTagList,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    fields: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    details: false,
    hoverDetails: false,
  }),
  computed: {
    instanceName () {
      const nameTag = this.value.Tags.find(({ Key }) => Key === 'Name')
      return nameTag ? nameTag.Value : ''
    },
    tags () {
      return this.value.Tags.filter(({ Key }) => _.lowerCase(Key) !== 'name')
    },
    stateColor () {
      return {
        pending: 'amber lighten-1',
        running: 'light-green accent-4',
        'shutting-down': 'amber lighten-1',
        terminated: 'red darken-1',
        stopping: 'amber lighten-1',
        stopped: 'red darken-1',
      }[this.value.State.Name] || 'grey'
    },
    SecurityGroups () {
      return _.map(this.value.SecurityGroups, ({ GroupName, GroupId }) => `${GroupName} (${GroupId})`).join(', ')
    },
    StateReason () {
      return this.value.StateReason.Message
    },
    BlockDeviceMappings () {
      return _.map(this.value.BlockDeviceMappings, ({ DeviceName, Ebs }) => `${DeviceName} (${Ebs.VolumeId})`).join(', ')
    },
    NetworkInterfaces () {
      return _.map(this.value.NetworkInterfaces, 'PrivateIpAddress').join(', ')
    },
    Monitoring () {
      return this.value.Monitoring.State
    },
    IamInstanceProfile () {
      const matched = this.value.IamInstanceProfile.Arn.match(IAM_INSTANCE_PROFILE_REGEX)
      // Remove the first character (a leading slash)
      return !_.isEmpty(matched) ? matched[0].substr(1) : ''
    },
    detailsFields () {
      let displayFields = []
      // Remove the fields already in the default selection
      if (this.details) displayFields = _.difference(_.keys(this.value), this.fields)
      return displayFields.filter((fieldName) => {
        // Exclude blacklisted fields
        if (EXCLUDED_FIELDS.includes(fieldName)) return false
        // Only shows non-null/empty fields
        return this.getPropertyValue(fieldName) !== null && this.getPropertyValue(fieldName) !== ''
      })
    },
  },
  methods: {
    getPropertyValue (path) {
      const sanitizedPath = this.$sanitizeHtml(path)
      // Find a property by its path. The _.get function used allow us to get a property by
      // its path, using the dot notation (ex.: 'State.Name')
      const val = _.get(this.value, sanitizedPath)
      // If the property is an object, we check if there is a computed property that may translate it to a string
      return _.isPlainObject(val) ? this[sanitizedPath] : val
    },
  },
  i18n: {
    messages: {
      en: {
        theValueBelow: 'the value below',
      },
      es: {
        theValueBelow: 'el valor a continuación',
      },
      fr: {
        theValueBelow: 'la valeur ci-dessous',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .prop-title {
    font-size: 10px;
    text-transform: uppercase;

    ::v-deep .cy-copy-btn {
      transition: opacity 0.1s ease-in;
      opacity: 0;
    }

    &:hover ::v-deep .cy-copy-btn {
      opacity: 1;
    }
  }

  .prop-value {
    display: flex;
    align-items: center;
    font-weight: bold;
    white-space: break-spaces;
  }

  .header .prop-value {
    text-transform: uppercase;
  }

  .details .prop-value {
    font-size: 12px;
  }

  .details .details-icon {
    position: absolute;
    right: 10px;
  }

  .state {
    flex-shrink: 0;
    width: 10px;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .instance-content {
    width: 300px;
  }

  .line {
    height: inherit !important;

    &__header,
    &__subheader--sticky {
      position: sticky;
      top: 0;
      background: white;
    }

    &__header {
      z-index: 1;
    }

    &__subheader {
      top: 55px;
    }
  }

  .tag ::v-deep .v-chip {
    background-color: #253149;
  }

  .tag ::v-deep .v-chip__content {
    height: 20px;
    padding: 5px;
    font-size: 10px;
  }

  ::v-deep .tag-list--expanded .tag {
    display: inline;
    flex-wrap: wrap;
    word-break: break-all;
    white-space: initial;
  }

  .expand-enter-active,
  .expand-leave-active {
    max-height: 230px;
    overflow-y: hidden;
    transition: max-height 0.2s ease-in;
  }

  .expand-enter,
  .expand-leave-to {
    max-height: 0;
    transition: max-height 0.2s ease-in;
  }
</style>
