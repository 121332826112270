<template>
  <div class="top-panel__container">
    <div class="top-panel__notifications">
      <CyNotification
        v-if="!_.isEmpty(errors)"
        class="width-100"
        theme="error"
        :title="$t('infraViewConfigErrorTitle')"
        :content="errors"/>
    </div>

    <div class="top-panel__content">
      <div class="left-box">
        <template v-if="!isOldView">
          <CyBtnToggle
            v-if="!isOldView && hasDiagram"
            v-model="currentMode"
            :items="$static.modesItems"
            class="mode-switch"/>
        </template>
      </div>

      <!-- TODO: FE#4336 IV 3.0: Remove the old IV and add refresh date and button here -->
      <CyButton
        variant="tertiary"
        class="btn-switch-version px-2"
        @click="toggleOldView">
        {{ isOldView ? $t('useNewVersion') : $t('useOldVersion') }}
      </CyButton>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import CyBtnToggle from '@/components/btn-toggle'

export default {
  name: 'CyInfraViewTopPanel',
  components: {
    CyBtnToggle,
  },
  props: {
    errors: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState('organization/project/infraview', {
      mode: (state) => state.mode,
    }),
    ...mapGetters('organization/project/infraview', [
      'hasDiagram',
    ]),
    $static () {
      return {
        modesItems: [
          {
            key: 'diagram',
            value: 'diagram',
            icon: 'fas fa-project-diagram',
            text: this.$t('mode.diagram'),
          },
          {
            key: 'list',
            value: 'list',
            icon: 'fas fa-th-list',
            text: this.$t('mode.list'),
          },
        ],
      }
    },
    currentMode: {
      get () {
        return this.mode
      },
      set (mode) {
        this.SET_MODE({ mode })
      },
    },
    isOldView () {
      return this.mode === 'oldVersion'
    },
  },
  methods: {
    ...mapMutations('organization/project/infraview', [
      'SET_MODE',
    ]),
    toggleOldView () {
      this.mode === 'oldVersion'
        ? this.SET_MODE({ mode: 'diagram' })
        : this.SET_MODE({ mode: 'oldVersion' })
    },
  },
  i18n: {
    messages: {
      en: {
        infraViewConfigErrorTitle: 'InfraView configuration error',
        mode: {
          diagram: 'Diagram',
          list: 'List',
        },
        useNewVersion: 'Use the new version',
        useOldVersion: 'Use previous version',
      },
      es: {
        infraViewConfigErrorTitle: 'Error de configuración de InfraView',
        mode: {
          diagram: 'Diagrama',
          list: 'Lista',
        },
        useNewVersion: 'Usar la nueva versión',
        useOldVersion: 'Usar la versión anterior',
      },
      fr: {
        infraViewConfigErrorTitle: 'Erreur de configuration InfraView',
        mode: {
          diagram: 'Diagramme',
          list: 'Liste',
        },
        useNewVersion: 'Utiliser la nouvelle version',
        useOldVersion: 'Utiliser la version précédente',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.top-panel {
  &__container {
    flex: 1 0 auto;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    .left-box {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .env-list {
      max-width: 200px;
      margin: 0;
      margin-right: 24px;

      ::v-deep .v-input__slot {
        margin-bottom: 0;
      }
    }

    .mode-switch {
      margin-bottom: -2px;
    }
  }
}

.v-btn.btn-switch-version {
  align-self: center;
  font-size: map.get($font-sizes, "sm");
}
</style>
