<template>
  <v-col
    :class="['pa-0 d-flex infra-view', {
      'infra-view--loading': loading,
      'py-4': !_.isEmpty(errors),
    }]">
    <CyInfraViewTopPanel :errors="errors"/>

    <v-progress-circular
      v-show="loading"
      v-has-rights-to="['GetProjInfrastructure', projectCanonical]"
      size="100"
      indeterminate
      color="secondary"
      class="loader"/>

    <CyInfraViewDiagram
      :key="selected.env"
      v-has-rights-to="['GetProjInfrastructure', projectCanonical]"
      :loading="loading"
      @stop-loading="stopLoading"/>
  </v-col>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { constructBreadcrumb, checksPass } from '@/utils/helpers'
import CyInfraViewDiagram from '@/components/infra-view/diagram'
import CyInfraViewTopPanel from '@/components/infra-view/top-panel'

export default {
  name: 'CyPageInfraview',
  components: {
    CyInfraViewDiagram,
    CyInfraViewTopPanel,
  },
  breadcrumb () {
    const { projectCanonical, projectName, envCanonical } = this
    return constructBreadcrumb(this.$options.name, envCanonical, [
      {
        label: this.$t('routes.projectEnvironments'),
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical, envCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  props: {
    projectCanonical: {
      type: String,
      default: '',
    },
    envCanonical: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapState('organization/project', {
      selected: (state) => state.selected,
    }),
    ...mapState('organization/project/infraview', {
      fetchInProgress: (state) => state.fetchInProgress,
      infraViewErrors: (state) => state.errors,
    }),
    ...mapGetters('organization/project', [
      'envs',
    ]),
    errors () {
      return this.infraViewErrors.filter(({ code }) => code !== 'NotFound')
    },
  },
  watch: {
    envCanonical: {
      async handler (env) {
        this.$toggle.loading(true)
        const canSelectEnv = checksPass({
          isEnvPopulated: !_.isEmpty(env),
          isEnvNewSelection: !_.isEqual(this.selected.env, env),
          isEnvInAvailableEnvs: _.some(this.envs, ({ canonical }) => canonical === env),
        })
        if (canSelectEnv) this.SELECT_ENV(env)
        await this.GET_INFRA(env)
        this.stopLoading()
      },
      immediate: true,
    },
  },
  async mounted () {
    if (!_.isEmpty(this.envs) && _.isEmpty(this.selected.env)) {
      this.SELECT_ENV(this.envs[0].canonical)
    }
    await this.GET_PROJECT_PIPELINES()
  },
  beforeDestroy () {
    this.RESET_INFRA_STATE()
  },
  methods: {
    ...mapActions('organization/project/infraview', [
      'GET_INFRA',
    ]),
    ...mapActions('organization/project', [
      'GET_PROJECT_PIPELINES',
    ]),
    ...mapMutations('organization/project', [
      'SELECT_ENV',
    ]),
    ...mapMutations('organization/project/infraview', [
      'RESET_INFRA_STATE',
    ]),
    stopLoading () {
      if (!this.fetchInProgress.diagram) this.$toggle.loading(false)
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.infraView',
      },
      es: {
        title: '@:routes.infraView',
      },
      fr: {
        title: '@:routes.infraView',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.infra-view {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  &--loading {
    @extend %is-saving;
  }
}

::v-deep .v-card {
  .new-header & {
    background-color: transparent !important;
  }
}

.loader {
  position: absolute;
  z-index: 1;
}
</style>
