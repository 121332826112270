<template>
  <div class="right-panel-content-tags">
    <template v-if="readonly">
      <CyTag
        v-for="(value, key) in visibleTags"
        :key="key"
        :label="key"
        variant="default"
        small
        class="visible-tag">
        <span class="ml-1">{{ value }}</span>
      </CyTag>
    </template>

    <template v-else>
      <div class="stack-level-tags">
        <div class="stack-level-tags__content">
          <CyTag
            v-for="(value, key) in entity._stackLevelTags().cycloid"
            :key="key"
            :label="key"
            class="tag--cycloid"
            variant="default"
            small>
            <span class="ml-1">{{ value }}</span>
          </CyTag>

          <CyTag
            v-for="(value, key) in entity._stackLevelTags().stack"
            :key="key"
            :label="key"
            class="tag--global"
            variant="default"
            small>
            <span class="ml-1">{{ value }}</span>
          </CyTag>
        </div>
      </div>

      <div
        v-if="hasNoTags && !show.addTagForm"
        class="no-tags">
        <p class="no-tags__header">
          <span class="header__text">{{ $t('noTags') }}</span>
          <CyButton
            icon-only
            icon="add"
            xs
            theme="primary"
            variant="secondary"
            @click="showAddTagForm"/>
        </p>

        <CyDevBtn
          class="mt-2 mx-auto"
          @click.native="devMode.addTag">
          easy add tag
        </CyDevBtn>
      </div>

      <template v-else>
        <CyTag
          v-for="(value, key) in visibleTags"
          :key="key"
          :label="key"
          variant="default"
          element-type="button"
          icon-after="close"
          small
          @click-icon-after="removeTag(key)">
          <span class="ml-1">{{ value }}</span>
        </CyTag>

        <div
          v-if="!show.addTagForm"
          class="add-tag__button">
          <CyButton
            icon-only
            icon="add"
            xs
            @click="showAddTagForm"/>

          <CyDevBtn
            class="mt-2 mx-auto"
            @click.native="devMode.addTag">
            easy add tag
          </CyDevBtn>
        </div>

        <div
          v-else
          class="add-tag">
          <div class="add-tag__header">
            <h4 class="add-tag__title">
              {{ $t('addTag') }}
            </h4>
            <div class="add-tag__actions">
              <CyButton
                icon-only
                icon="close"
                theme="error"
                variant="secondary"
                @click="hideAddTagForm"/>

              <CyButton
                :disabled="!canSave"
                icon-only
                icon="done"
                theme="secondary"
                variant="primary"
                @click="addTag(newTag.key, newTag.value)"/>
            </div>
          </div>
          <v-text-field
            v-model="$v.newTag.key.$model"
            :error-messages="errors.key"
            :label="$t('forms.key')"
            required
            class="required-field"/>
          <v-text-field
            v-model="$v.newTag.value.$model"
            :error-messages="errors.value"
            :label="$t('forms.value')"
            required
            class="required-field"/>
        </div>
      </template>
    </template>

    <CyButton
      v-if="total.tagsCount > $static.TAG_LIMIT"
      class="toggle-tags-shown-btn"
      theme="secondary"
      variant="secondary"
      sm
      @click="$toggle.show.allTags">
      <span>
        {{
          !show.allTags
            ? $tc('showMoreTags', total.hiddenTagsCount, { count: total.hiddenTagsCount })
            : $tc('hideLastTags', total.tagsOverLimitCount, { count: total.tagsOverLimitCount })
        }}
      </span>
    </CyButton>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { findDuplicates } from '@/utils/helpers'
import { faker } from '@faker-js/faker'

const { lorem } = faker

export const TAG_LIMIT = 5

export default {
  name: 'CyInfraViewRightPanelTags',
  props: {
    entity: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  validations () {
    return this.readonly
      ? {}
      : {
          newTag: {
            key: {
              required,
              isUnique: () => this.keyIsUnique,
              isNotCycloidTag: () => this.doesNotClashWith('cycloid'),
              isNotCustomTag: () => this.doesNotClashWith('stack'),
            },
            value: { required },
          },
        }
  },
  data: () => ({
    tags: {},
    show: {
      allTags: false,
      addTagForm: false,
    },
    newTag: {
      key: '',
      value: '',
    },
  }),
  computed: {
    $static: () => ({
      TAG_LIMIT,
    }),
    errors () {
      if (this.readonly) return { key: [], value: [] }
      return {
        key: (() => {
          const { $dirty, required, isUnique, isNotCycloidTag, isNotCustomTag } = this.$v.newTag.key
          const errors = []
          if (!$dirty) return errors
          if (!required) errors.push(this.$t('forms.fieldRequired'))
          if (!isUnique) errors.push(this.$t('forms.fieldMustBeUnique'))
          if (!isNotCycloidTag) errors.push(this.$t('cannotMatchCycloidTag'))
          if (!isNotCustomTag) errors.push(this.$t('cannotMatchCustomTag'))
          return errors
        })(),
        value: (() => {
          const { $dirty, required } = this.$v.newTag.value
          const errors = []
          if (!$dirty) return errors
          if (!required) errors.push(this.$t('forms.fieldRequired'))
          return errors
        })(),
      }
    },
    hasNoTags () {
      return _.isEmpty(this.tags)
    },
    visibleTags () {
      if (this.show.allTags) return this.tags
      const visibleTags = {}
      _.entries(this.tags).forEach(([key, value], index) => {
        if (index >= TAG_LIMIT) return
        visibleTags[key] = value
      })
      return visibleTags
    },
    canSave () {
      return !this.$v.$invalid
    },
    total () {
      const tagsCount = Object.keys(this.tags).length
      const visibleTagsCount = Object.keys(this.visibleTags).length
      const hiddenTagsCount = tagsCount - visibleTagsCount
      const tagsOverLimitCount = tagsCount - TAG_LIMIT
      return {
        tagsCount,
        visibleTagsCount,
        hiddenTagsCount,
        tagsOverLimitCount,
      }
    },
    keyIsUnique () {
      return findDuplicates([..._.keys(this.tags), this.newTag.key]).length === 0
    },
  },
  created () {
    this.devMode = { addTag: () => this.addTag(lorem.word(), lorem.word()) }
  },
  mounted () {
    this.tags = _.cloneDeep(this.entity.tags || {})
  },
  methods: {
    showAddTagForm () {
      if (this.readonly) return
      this.resetNewTag()
      this.show.addTagForm = true
    },
    hideAddTagForm () {
      this.resetNewTag()
      this.show.addTagForm = false
    },
    resetNewTag () {
      this.newTag = { key: '', value: '' }
      this.$v.$reset()
    },
    addTag (key, value) {
      this.$set(this.tags, key, value)
      this.$emit('add-tag', { [key]: value })
      this.hideAddTagForm()
    },
    removeTag (key) {
      this.$delete(this.tags, key)
      this.$emit('remove-tag', key)
    },
    doesNotClashWith (globalTagType) {
      if (this.readonly) return false
      const globalTags = this.entity._stackLevelTags()[globalTagType]
      return findDuplicates([..._.keys(globalTags), this.newTag.key]).length === 0
    },
  },
  i18n: {
    messages: {
      en: {
        addTag: 'Add Tag',
        cannotMatchCustomTag: 'This key is already declared in global tags',
        cannotMatchCycloidTag: 'This is a reserved Cycloid tag',
        hideLastTags: 'Hide last tag | Hide last {count} tags',
        noTags: 'No tags',
        showMoreTags: 'Show 1 more tag | Show {count} more tags',
      },
      es: {
        addTag: 'Añadir Tag',
        cannotMatchCustomTag: 'Este tag ya está declarado a nivel global',
        cannotMatchCycloidTag: 'Éste es un tag reservado Cycloid',
        hideLastTags: 'Ocultar el último tag | Ocultar los últimos {count} tags',
        noTags: 'Ningún tags',
        showMoreTags: 'Mostrar 1 tag más | Mostrar {count} tags más',
      },
      fr: {
        addTag: 'Ajouter un Tag',
        cannotMatchCustomTag: 'Cette clé est déjà déclarée dans les tags globaux',
        cannotMatchCycloidTag: 'Ceci est un tag Cycloid réservé',
        hideLastTags: 'Masquer le dernier tag | Masquer les {count} derniers tags',
        noTags: 'Pas de tags',
        showMoreTags: 'Afficher 1 tag supplémentaire | Afficher {count} tags supplémentaires',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .right-panel-content-tags {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.75em;

    .no-tags {
      &__header {
        display: flex;
        margin: 0;

        .header {
          &__icon {
            margin-left: 0.3em;
          }
        }
      }
    }

    .tag {
      max-width: fit-content;
      margin-bottom: 4px;

      &--global {
        border-left: 6px solid get-color("secondary");
      }
    }

    .add-tag {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin-bottom: 8px;
      padding-top: 1em;

      &__button {
        padding-top: 1em;
      }

      &__header {
        display: flex;
        justify-content: space-between;
      }

      &__title {
        padding-bottom: 1em;
        color: get-color("primary");
      }

      ::v-deep .v-btn {
        margin: 0;
      }

      ::v-deep .v-input {
        padding: 0.5em 0;
      }
    }

    > .display-all-tags {
      display: block;
      margin: 10px auto 5px;
    }

    .toggle-tags-shown-btn {
      align-self: center;
      padding-left: 1em;
      font-size: map.get($font-sizes, "base");
      font-weight: $font-weight-normal;
    }

    .stack-level-tags {
      padding-bottom: 1em;

      &__content {
        display: flex;
        flex-direction: column;
      }

      &__tag {
        background-color: get-color("grey");
        box-shadow: none;
        color: get-color("grey", "dark-2");

        ::v-deep .v-chip__content {
          padding: 0 0.5em;
        }
      }
    }
  }
</style>
